.markdown-body {
  pre {
    padding: 0;
  }

  pre,
  code {
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  }

  pre code {
    display: block;
    overflow-x: auto;
    padding: 1em;
  }

  code {
    padding: 3px 5px;
  }

  .hljs,
  pre {
    background: #1a1b26;
    color: #cbd2ea;
  }

  /*!
  Theme: Tokyo-night-Dark
  origin: https://github.com/enkia/tokyo-night-vscode-theme
  Description: Original highlight.js style
  Author: (c) Henri Vandersleyen <hvandersleyen@gmail.com>
  License: see project LICENSE
  Touched: 2022
*/
  .hljs-comment,
  .hljs-meta {
    color: #565f89;
  }

  .hljs-deletion,
  .hljs-doctag,
  .hljs-regexp,
  .hljs-selector-attr,
  .hljs-selector-class,
  .hljs-selector-id,
  .hljs-selector-pseudo,
  .hljs-tag,
  .hljs-template-tag,
  .hljs-variable.language_ {
    color: #f7768e;
  }

  .hljs-link,
  .hljs-literal,
  .hljs-number,
  .hljs-params,
  .hljs-template-variable,
  .hljs-type,
  .hljs-variable {
    color: #ff9e64;
  }

  .hljs-attribute,
  .hljs-built_in {
    color: #e0af68;
  }

  .hljs-keyword,
  .hljs-property,
  .hljs-subst,
  .hljs-title,
  .hljs-title.class_,
  .hljs-title.class_.inherited__,
  .hljs-title.function_ {
    color: #7dcfff;
  }

  .hljs-selector-tag {
    color: #73daca;
  }

  .hljs-addition,
  .hljs-bullet,
  .hljs-quote,
  .hljs-string,
  .hljs-symbol {
    color: #9ece6a;
  }

  .hljs-code,
  .hljs-formula,
  .hljs-section {
    color: #7aa2f7;
  }

  .hljs-attr,
  .hljs-char.escape_,
  .hljs-keyword,
  .hljs-name,
  .hljs-operator {
    color: #bb9af7;
  }

  .hljs-punctuation {
    color: #c0caf5;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: 700;
  }
}
