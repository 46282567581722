.amz-head{
    position: sticky;
    top:0;
    z-index:3;
    background-color: var(--white);
    // height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:12px 24px;
    border-bottom: var(--border-normal);
    .amz-head-logo{
        width: 120px;
        height: auto;
    }
    .amz-head-tab{
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        cursor: pointer;
        .amz-head-appcenter{
            +.amz-head-appcenter{
                margin-left: 48px;
            }
            font-size: 20px;
        }
        .amz-head-vipicon{
          
        }
    }
    .amz-head-user{
        display: flex;
        align-items: center;
        .amz-head-help{
            color:var(--black);
            font-size: 12px;
            margin-right: 34px;
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
                margin-right: 6px;

            }
        }
    }
}