
@mixin container {
    background-color: var(--white);
    // border: var(--border-in-light);
    // border-radius: 20px;
    box-shadow: var(--shadow);
    color: var(--black);
    background-color: var(--white);
    // min-width: 1200px;
    min-height: 480px;
    overflow: auto;
    // display: flex;
    // overflow: hidden;
    box-sizing: border-box;
  
    width: var(--window-width);
    height: var(--window-height);
  }
  
  .container {
    @include container();
  }
  
  @media only screen and (max-width: 600px) {
    .container {
      --window-width: 100vw;
      --window-height: 100vh;
      --window-content-width: calc(100% - var(--sidebar-width));
  
      @include container();
      min-width: unset;
      min-height: unset;
      max-width: 100vw;
      max-height: var(--window-height);
  
      border-radius: 0;
    }
  }