.amz_img{
    display: flex;
    height: calc(100vh - 62px);
    .amz_img_list{
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 15%;
        max-width: 180px;
        min-width: 80px;
        border-right: 1px solid var(--primary);
        .amz_img_listTitle{
            border-bottom: 1px solid var(--primary);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 10px;
            cursor: pointer;
            user-select: none;
            font-size:12px

        }
        .amz_img_listCon{
            margin:10px 0;
            flex: 1;
            overflow-y: auto;
            padding:0 5px;
           .amz_img_listBox{
            border-radius: 10px;
            width: 100%;
            box-sizing: border-box;
            padding-top: 100%;
            position: relative;
            +.amz_img_listBox{
                margin-top: 10px;
            }
            &.active{
                background-color: var(--primary);
            }
            .amz_img_listItem{
                position: absolute;
                top:3px;
                left:3px;
                width: calc(100% - 6px);
                height: calc(100% - 6px);
                cursor: pointer;
                
                background-image: url(../../assets/image/demo.png);
                background-size: cover;
                background-position: center;
                border-radius: 10px;
                .amz_img_listSelect{
                    position: absolute;
                    top:5px;
                    right: 5px;
                    .unselect{
                        width: 20px;
                        height: 20px;
                        border:2px solid #d4d4d4;
                        border-radius: 50%;
                        background-color: rgba($color: #000000, $alpha: .5);
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.active{
                            background-color: var(--primary);
                            border-color: var(--primary);
                        }
                    }
                }
            }
           }
        }
        .amz_img_listMore{
            border-top: 1px solid var(--primary);
            padding: 10px 0;
            display: flex;
            justify-content: center;
            font-size: 12px;
            .amz_img_moreBtn{
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

        }
    }
    .amz_img_content{
        padding: 10px;
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        flex-direction: column;
        .amz_img_imgbox{
            flex: 1;
            height: calc(100% - 100px);
            display: flex;
            .amz_img_imgitem{
                width: calc(100% - 300px);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .img{
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 10px;
                }
            }
            .amz_img_imgconfig{
                border:1px solid var(--primary);
                width: 300px;
                height: 100%;
                border-radius: 10px;
            }

        }
        .amz_img_input{
            height: 100px;

        }
    }
    .amz_img_config{

    }
}