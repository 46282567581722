:global{
    .waterfall-item{
        &:hover{
            .star{
                display: block;
            }
        }
        .img-box{
            overflow: hidden;
        }
        .image-visible{
            transition: all .25s ease-in-out!important;
            &:hover{
                transform: scale(1.3);
            }
        }
    }
}