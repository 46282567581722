.amz-head{
    position: fixed;
    top:0;
    width: 100%;
    box-sizing: border-box;
    z-index:3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:14px 24px;
    transition:all .3s;
    &.amz-head-scroll{
        background-color: #fff;
        border-bottom: var(--border-normal);

    }
    .amz-head-logo{
        width: 120px;
        height: auto;
    }
    
    .amz-head-user{
        display: flex;
        align-items: center;
        .amz-head-help{
            color:var(--black);
            font-size: 12px;
            margin-right: 34px;
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
                margin-right: 6px;
            }
        }
    }
}