@keyframes toggle {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
.amz-home{
    .amz-home-topbanner{
        background-image: url(../../assets/image/banner.jpeg);
        background-size: cover;
        background-position: center;
        .amz-home-toptitle{
            color: #0045FF;
            font-weight: bold;
            width: 500px;
            line-height: 1.2;
        }
        .amz-home-topdes{
            color: #4C4D4F;
        }
        .amz-home-topbtn{
            animation-name: toggle;
            animation-duration: 1.5s;
            animation-timing-function: ease;
            animation-iteration-count:infinite
        }

    }
    .amz-home-descript{
        .amz-home-descriptTitle{
            font-weight: bold;
            color:#000;
            text-align: center;
        }
        .amz-home-descriptItems{
            justify-content: space-between;
            .amz-home-descriptItem{
                box-sizing: border-box;
                background: linear-gradient(180deg, #E1E6F5 0%, rgba(255, 255, 255, 0) 100%);
                border-radius: 10px;
                color: #000;
                transition: all .3s;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                    // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
                }
                .amz-home-itemTitle{
                    font-weight: bold;
                }
            }
        }
    }
    .amz-home-our{
        background: #4451F7;
        color: #fff;
        border-radius: 35px;
        max-width: 1200px;
        margin: 80px auto;
        text-align: center;
        position: relative;
        overflow: hidden;
    }
    .amz-home-btminner{
        background-image: url(../../assets/image/btmBanner.jpeg);
        background-size: auto 100%;
        background-position: center;
        text-align: center;
        .amz-home-btminnerTitle{
            // font-size: 44px;
            font-weight: bold;
            color: #000;
        }
        .amz-home-topbtn{
            border-radius: 30px;
        }

    }
}