@tailwind base;
@tailwind components;
@tailwind utilities;

@mixin light {
    /* color */
    --title-color:#000000;
    --desc-color:#666;
    --tag-color:#999;
    --white: white;
    --black: #111;
    --gray: rgb(250, 250, 250);
    --primary: #3C6EF6;
    // --second: rgb(231, 248, 255);
    // --hover-color: #f3f3f3;
    --bar-color: rgba(0, 0, 0, 0.1);
    --theme-color: var(--gray);
  
    /* shadow */
    --shadow: 50px 50px 100px 10px rgb(0, 0, 0, 0.1);
    --card-shadow: 0px 2px 4px 0px rgb(0, 0, 0, 0.05);
  
    /* stroke */
    --border-in-light: 1px solid rgb(222, 222, 222);
    --border-normal: 1px solid #F5F3F3
  }
  
  @mixin dark {
    /* color */
    --white: rgb(30, 30, 30);
    --black: rgb(187, 187, 187);
    --gray: rgb(21, 21, 21);
    --primary: #3C6EF6;
    // --second: rgb(27 38 42);
    // --hover-color: #323232;
  
    --bar-color: rgba(255, 255, 255, 0.1);
  
    --border-in-light: 1px solid rgba(255, 255, 255, 0.192);
  
    --theme-color: var(--gray);
  }
  
  .light {
    @include light;
  }
  
  // .dark {
  //   @include dark;
  // }
  
  .mask {
    filter: invert(0.8);
  }
  
  :root {
    @include light;
  
    --window-width: 100vw;
    --window-height: 100vh;
    --sidebar-width: 300px;
    --window-content-width: calc(100% - var(--sidebar-width));
    --message-max-width: 80%;
    --full-height: 100%;
  }
  
  @media only screen and (max-width: 600px) {
    :root {
      --window-width: 100vw;
      --window-height: var(--window-height);
      --sidebar-width: 100vw;
      --window-content-width: var(--window-width);
      --message-max-width: 100%;
    }
  
    .no-mobile {
      display: none;
    }
  }
  
  // @media (prefers-color-scheme: dark) {
  //   :root {
  //     @include dark;
  //   }
  // }
  html {
    height: var(--full-height);
  }
  
  body {
    background-color: var(--gray);
    color: var(--black);
    margin: 0;
    padding: 0;
    height: var(--full-height);
    width: 100vw;
    // user-select: none;
    font-family: "PingFang SC","Noto Sans SC", "SF Pro SC", "SF Pro Text", "SF Pro Icons",
       "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  
    @media only screen and (max-width: 600px) {
      // background-color: var(--second);
    }
  }
  
  ::-webkit-scrollbar {
    --bar-width: 5px;
    width: var(--bar-width);
    height: var(--bar-width);
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--bar-color);
    border-radius: 20px;
    background-clip: content-box;
    border: 1px solid transparent;
  }
  
  select {
    border: var(--border-in-light);
    padding: 10px;
    border-radius: 10px;
    appearance: none;
    cursor: pointer;
    background-color: var(--white);
    color: var(--black);
    text-align: center;
  }
  
  label {
    cursor: pointer;
  }
  
  input {
    // text-align: center;
  }
  
  input[type="checkbox"] {
    cursor: pointer;
    background-color: var(--white);
    color: var(--black);
    appearance: none;
    border: var(--border-in-light);
    border-radius: 5px;
    height: 16px;
    width: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  input[type="checkbox"]:checked::after {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: var(--primary);
    content: " ";
    border-radius: 2px;
  }
  
  input[type="range"] {
    appearance: none;
    background-color: var(--white);
    color: var(--black);
  }
  
  @mixin thumb() {
    appearance: none;
    height: 8px;
    width: 20px;
    background-color: var(--primary);
    border-radius: 10px;
    cursor: pointer;
    transition: all ease 0.3s;
    margin-left: 5px;
    border: none;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    @include thumb();
  }
  
  input[type="range"]::-moz-range-thumb {
    @include thumb();
  }
  
  input[type="range"]::-ms-thumb {
    @include thumb();
  }
  
  @mixin thumbHover() {
    transform: scaleY(1.2);
    width: 24px;
  }
  
  input[type="range"]::-webkit-slider-thumb:hover {
    @include thumbHover();
  }
  
  input[type="range"]::-moz-range-thumb:hover {
    @include thumbHover();
  }
  
  input[type="range"]::-ms-thumb:hover {
    @include thumbHover();
  }
  
  input[type="number"],
  input[type="text"],
  input[type="password"] {
    appearance: none;
    // border-radius: 10px;
    border: var(--border-in-light);
    // min-height: 36px;
    // box-sizing: border-box;
    background: var(--white);
    color: var(--black);
    // padding: 0 10px;
    // max-width: 50%;
  }
  
  div.math {
    overflow-x: auto;
  }
  
  .modal-mask {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    height: var(--full-height);
    width: 100vw;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .link {
    font-size: 12px;
    color: var(--primary);
    text-decoration: none;
  
    &:hover {
      text-decoration: underline;
    }
  }
  svg,img{
    display: unset;
  }
  pre {
    position: relative;
  
    &:hover .copy-code-button {
      pointer-events: all;
      transform: translateX(0px);
      opacity: 0.5;
    }
  
    .copy-code-button {
      position: absolute;
      right: 10px;
      top: 1em;
      cursor: pointer;
      padding: 0px 5px;
      background-color: var(--black);
      color: var(--white);
      border: var(--border-in-light);
      border-radius: 10px;
      transform: translateX(10px);
      pointer-events: none;
      opacity: 0;
      transition: all ease 0.3s;
  
      &:after {
        content: "copy";
      }
  
      &:hover {
        opacity: 1;
      }
    }
  }
  
  .clickable {
    cursor: pointer;
  
    div:not(.no-dark) > svg {
      filter: invert(0.5);
    }
  
    &:hover {
      filter: brightness(0.9);
    }
  }
  
  .error {
    width: 80%;
    border-radius: 20px;
    border: var(--border-in-light);
    box-shadow: var(--card-shadow);
    padding: 20px;
    overflow: auto;
    background-color: var(--white);
    color: var(--black);
  
    pre {
      overflow: auto;
    }
  }
  a{
    color:#0045FF
  }
  .scrollbarHide::-webkit-scrollbar {
    display: none;
  }
  
  /* 隐藏 IE、Edge 和 Firefox 的滚动条 */
  .scrollbarHide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }