@keyframes slidein {
    from {
      transform: translateX(-100%);
    }
  
    to {
        transform: translateX(0);
    }
  }
.amz-login{
    // width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    box-shadow: var(--shadow);
    .amz-login-banner{
        animation-duration: .5s;
        animation-name: slidein;
        width: 25%;
        background-color: #4084ff;
        background-image: url(../../assets/image/loginBanner.svg);
        background-size: cover;
        background-repeat: no-repeat;
        color:#FFF;
        padding: 190px 60px 0;
        position: relative;
        &.amz-login-bannerhide{
            width: 0;
            display: none;
        }
        .amz-login-logo{
            position: absolute;
            top: 22px;
            left: 24px;
            width: 186px;
            height: auto;
        }
        .amz-login-title{
            font-size: 48px;
            line-height: 1.3;
        }
        .amz-login-descript{
            font-size: 26px;
            margin-top: 20px;
            line-height: 1.4;
        }
    }
    .amz-login-box{
        width: 75%;
        position: relative;
        flex: 1;
        display: flex;
            align-items: center;
            justify-content: center;
        .amz-login-box-top{
            position: absolute;
            top:20px;
            right:100px;
            font-size: 14px;
            color: #1d2129;
        }
       
        
        .amz-login-content{
            box-shadow: 0px 0px 32px rgba(0, 69, 255, 0.11);
            // height: 520px;
            border-radius: 16px;
            // width: 500px;
            padding:0 30px;
            position: relative;
            .amz-login-way{
                position: absolute;
                top:10px;
                right: 10px;
                cursor: pointer;
                img{
                    width: 100%;
                }
            }
            .amz-login-box-bottom{
                position: absolute;
                bottom: 30px;
                left: 0;
                width: 100%;
                text-align: center;
            }
            .amz-login-content-wechat{
                text-align: center;
                .amz-login-content-wtitle{
                    color:#111;
                    font-weight: bold;
                }
                .amz-login-content-codebox{
                    padding:16px 16px 56px;
                    background-color:  #F8FAFF;
                    position: relative;
                    display: inline-block;
                    border-radius: 18px;
                    .qrLoading{
                        background-color: rgba(0, 0, 0, .6);
                        width: 200px;
                        height: 200px;
                        color: #fff;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        cursor: pointer;
                    }
                    .amz-login-content-wbottom{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: calc(100% - 32px);
                        img{
                           width: 20px; 
                           margin-right: 8px;
                        }
                        position: absolute;
                        bottom: 15px;
                        font-size: 14px;
                        color:#000
                    }
                }
                
            }
            .amz-login-content-code{
                .amz-login-content-ctitle{
                    color: #111;
                    font-weight: bold;
                    text-align: center;
                }
                .amz-login-content-cform{
                    margin-top: 40px;
                &.isMobel{
                    input{
                        font-size: 16px;
                    }
                    .codeinputAfter{
                        font-size: 16px;
                    }
                    .amz-login-content-btn{
                        background-color: #3C6EF6;
                        width: 100%;
                        font-size: 16px;
                        border-radius: 4px;
                        padding:8px 0;
                        height: unset;
                    }
                }
                 input{
                    font-size: 20px;
                    // padding:10px 0;
                    background-color: #F8FBFF!important;
                   
                 }
                 :global{
                    .inputSelect{
                        .arco-select-view-value{
                            font-size:20px ;
                            line-height: 50px;
                        }
                        .arco-select-arrow-icon{
                            font-size: 16px;
                        }
                     }
                     .inputSelectList{
                        .arco-select-option{
                            font-size: 20px;
                        }
                     }
                     
                }
                
                 
                 .codeinput{
                    border-radius: 8px;
                    // padding-right: 150px;
                 }
                 
                 .codeinputAfter{
                    font-size: 20px;
                    color: #3C6EF6;
                    cursor: pointer;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    
                    &.disabled{
                        color: #ccc;
                        cursor: not-allowed;
                        img{
                            cursor: pointer;
                        }
                    }
                 }
                :global(.arco-input-inner-wrapper){
                    background-color: #F8FBFF;
                        font-size: 24px;
                }
                 :global(.arco-input-group-addbefore) {
                    
                    background-color: #F8FBFF;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    font-size: 24px!important;

                  }
                }
                .amz-login-content-csign{
                    font-size: 16px;
                }
                .amz-login-content-btn{
                    background-color: #3C6EF6;
                    width: 100%;
                    font-size: 20px;
                    border-radius: 8px;
                    padding:10px 0;
                    height: unset;
                }
                .amz-login-content-cbottom{
                    font-size: 14px;
                    color:#494948;
                    text-align: center;
                    padding-top:12px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        margin-right: 8px;
                        width: 20px;
                    }
                }
            }
            .amz-login-content-pwd{
                
                .amz-login-content-ctitle{
                    color: #111;
                    font-size: 28px;
                    font-weight: bold;
                    padding-top: 60px;
                    text-align: center;
                }
                .amz-login-content-cform{
                    margin-top: 40px;
                 input{
                    font-size: 20px;
                    padding:10px 0;
                    background-color: #F8FBFF!important;
                 }
                 .codeinput{
                    border-radius: 8px;
                    background-color: #F8FBFF;


                 }
                 :global{
                    .inputSelect{
                        .arco-select-view-value{
                            font-size:20px ;
                            line-height: 50px;
                        }
                        .arco-select-arrow-icon{
                            font-size: 16px;
                        }
                     }
                     .inputSelectList{
                        .arco-select-option{
                            font-size: 20px;
                        }
                     }
                     .arco-input-inner-wrapper{
                        background-color: #F8FBFF;
                        font-size: 24px;
                     }
                     .arco-input-group-addbefore{
                        background-color: #F8FBFF;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                        font-size: 24px!important;
                     }
                     
                }
               
                
                }
                .amz-login-content-csign{
                    font-size: 16px;
                }
                .amz-login-content-btn{
                    background-color: #3C6EF6;
                    width: 100%;
                    font-size: 20px;
                    border-radius: 8px;
                    padding:10px 0;
                    height: unset;
                }
                .amz-login-content-cbottom{
                    font-size: 14px;
                    color:#494948;
                    text-align: center;
                    padding-top:12px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        margin-right: 8px;
                        width: 20px;
                    }
                }
            }

        }
    }
}
