.amz-vip{
    // height: 2000px;
    min-height: calc(100vh - 61px);
    background-image: url(../../icons/vip/bg.svg);
    background-size: 100%;
    .amz-vip-title{
        text-align: center;
        margin-bottom: 36px;
        padding-top: 72px;
        .amz-vip-titleBig{
            color:var(--title-color);
            font-size: 36px;
            font-weight: bold;
        }
        .amz-vip-titleSub{
            color:var(--desc-color);
            font-size: 20px;
            margin-top: 10px;
        }
    }
    .amz-vip-content{
        display: flex;
        justify-content: center;
        .amz-vip-item{
            border-radius: 8px;
            overflow: hidden;
            width: 282px;
            background-color: var(--white);
            margin-top: 21px;
            +.amz-vip-item{
                margin-left: 24px;
            }
            .amz-vip-itemTop{
                color:#FFF;
                background-color: rgba(194, 205, 226, 0.64);
                padding:6px 0;
                font-size: 14px;
                text-align: center;
                &.active{
                    margin-top: 0;
                }
            }
            .amz-vip-itemTitle{
                font-size: 22px;
                font-weight: bold;
                color: var(--black);
                text-align: center;
                margin-top: 32px;
            }
            .amz-vip-itemSub{
                color:var(--tag-color);
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                margin-top: 8px;
            }
            .amz-vip-price{
                color:var(--black);
                font-size: 14px;
                text-align: center;
                margin-top: 36px;
                font-weight: bold;

                &.one{
                    font-size: 32px;
                    font-weight: bold;
                }
                .priceicon{
                    font-size: 14px;
                    color:#3C6EF6;
                    vertical-align: super;
                }
                .pricenum{
                    font-size: 32px;
                    color:#3C6EF6;
                }
                &.three{
                    .priceicon{
                        color:#FF6600
                    }
                    .pricenum{
                        color:#FF6600
                    }
                }
                
            }
            .amz-vip-start{
                
                border-radius: 8px;
                border: 1px solid #D8DFEC;
                color:#333;
                padding: 10px 0;
                font-size: 16px;
                width: calc(100% - 48px);
                margin:  30px auto 24px;
                text-align: center;
                cursor: pointer;
                &.two{
                    color: #fff;
                    background-color: #3C6EF6;
                }
                &.three{
                    color:#fff;
                    background-color: #FF6600;
                }
            }
        }
    }
    .amz-vip-desc{
        padding:0 24px 16px;
        .amz-vip-descTitle{
            font-size: 14px;
            color:var(--black);
            font-weight: bold;
        }
        .amz-vip-descDefault{
            display: flex;
            align-items: center;
            color: #333;
            font-size: 12px;
            margin-top: 8px;
            &::before{
                content: '';
                display: block;
                margin: 0 7px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #333;
            }

        }
        .amz-vip-descAdd{
            display: flex;
            align-items: center;
            color: #333;
            font-size: 12px;
            margin-top: 8px;
            img{
                margin:0 5px
            }
        }
    }
   
    
}

:global{
    .vip_dialog{
        .arco-modal{
            border-radius: 12px;
            overflow: hidden;
        }
        .arco-modal-header{
            background-color: #F1F6FE;
            padding:16px 24px;
            height: unset;
        }
        .arco-modal-close-icon{
            top: 32px!important;
        }

    }
    .amz-vip-dialogTitle{
        text-align: left;
        font-size: 14px;

        .amz-vip-upTitle{
            color: #333;
            font-weight: bold;
        }
        .amz-vip-version{
            color:#666;
        }
    }
    .amz-vip-dialogItem{
        +.amz-vip-dialogItem{
            margin-top: 24px;
        }
        .amz-vip-dialogItem-title{
            font-size: 16px;
            color: #111111;
            font-weight: bold;
        }
        .amz-vip-dialogItem-content{
            margin-top: 8px;
            font-size: 14px;
            background-color: #F9FAFB;
            font-size: 14px;
            border-radius: 8px;
            padding: 6px 12px;
            display: flex;
            flex-wrap: wrap;

            .item{
                padding: 6px 0;
                &:nth-child(even){
                    width: 60%;
                }
                &:nth-child(odd){
                    width: 40%;
                }
                color: #666;
                span{
                    color: #333;
                }
            }
        }
        .payBox{
            margin-top: 17px;
            display: flex;

            .payBoxLeft{
                border: 1px solid #E4E4E5;
                border-radius: 8px;
                width: 220px;
                height: 220px;
                padding: 10px;
                margin-right: 24px;
                position: relative;
                .codeInvalid{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,.5);
                    color: #fff;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                }
            }
            .payBoxRight{
                padding-top: 24px;
                flex: 1;
                .payBoxIcons{
                    display: flex;
                    border: 1px dashed #F1F6FE;
                    border-radius: 8px;
                    justify-content: space-between;
                    width:230px;
                    padding:10px 24px;
                    .payBoxIcon{
                         filter: grayscale(100%); 
                         cursor: pointer; 
                         &.active{
                            filter: unset;
                         } 
                    }
                }
                .payBoxText{
                    margin-top: 10px;
                    font-size: 14px;
                    color: #666;
                    .payBoxLink{
                        color: #3c6ef6!important;
                    }
                }
                .payBoxNumber{
                    margin-top: 33px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #333;
                    .payBoxPrice{
                        font-size: 32px;
                        color: #D95A47;
                    }
                }
                .payBoxDes{
                    margin-top: 6px;
                    color: #111;
                    font-size: 14px;
                    span{
                        color: #D95A47;
                    }
                }
            }
        }
    }
}
