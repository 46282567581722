.amz-agreement{
    padding: 80px 15px 10px;
    max-width: 1100px;
    text-align: justify;
    margin: 0 auto;
    line-height: 24px;
    .amz-agreement-title{
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }
    .amz-agreement-btitle{
        font-weight: bold;
        font-size: 16px;
        padding: 10px 0;
    }
    .amz-agreement-stitle{
        font-size: 14px;
        padding: 5px 0;
    }
    .amz-agreement-time{
        font-size: 14px;
    }

    .amz-agreement-content{
        font-size: 14px;
    }
    .amz-agreement-indent{
        padding-left: 30px;
        font-size: 14px;

    }
    .amz-agreement-blod{
        font-weight: bold;
    }
    .amz-agreement-underline{
        text-decoration: underline;
        font-weight: bold;

    }
    table{
        border-collapse:collapse; 
    }
    td{
        border: 1px solid #000;
        padding: 5px;
    }
}