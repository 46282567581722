.amz-app{
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;
    background-color: #f0f2f6;
    .amz-app-search{
        max-width: 900px;
        width: 80%;
        margin: 20px auto;
        background-color: #fff;
        :global{
            .arco-input-inner-wrapper{
                background-color: #fff;
            }
        }
    }
    .amz-app-tabs{
        margin: 0 auto;
        padding:0 10px;
        :global{
            .arco-tabs-header-wrapper{
                justify-content: center;
            }
        }
        
        
    }
    .self-icon{
        filter: grayscale(1);
        width:14px;
        height:14px;
        color:'inherit';
        &.active{
            filter: unset;
        }
    }
    .amz-app-tab{
        
    }
    .amz-app-tabItems{
        display: flex;
        flex-wrap: wrap;
        // gap: 16px;
        // grid-template-columns: repeat(4,1fr);
        margin-top: 16px;
        .amz-app-tabItem{
            box-sizing: border-box;
            padding:24px 16px;
            width: calc(100% - 20px); 
            background-color: #fff;
            height: 184px;
            border-radius: 5px;
            border: 1px solid rgba(255,255,255,.1);
            box-shadow: 0 2px 20px #c8cacf99;
            position: relative;
            cursor: pointer;
            margin: 0 auto;
            .tabitem-title{
                color:#545454;
                font-size: 16px;
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .tabitem-content{
                color: #545454;
                font-size: 12px;
                margin-top: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            .tabitem-from{
                font-size: 12px;
                position: absolute;
                color: #bebebe;
                right: 16px;
                bottom: 12px;
                display: flex;
                align-items: center;
            }
            .tabitem-tool{
                position:absolute;
                left: 6px;
                bottom: 0;
                padding: 10px;
            }
        }
    }
    
}
:global{
    .amz-app-model{
        width: 900px;
    }
}
@media only screen and (max-width: 600px) {
   .amz-app{
    .amz-app-search{
        width: 90%;
        :global{
            .arco-input-inner-wrapper{
                background-color: #fff;
            }
        }
    }
    .amz-app-tabs{
        width: 100vw;
        padding:0;

        :global{
            .arco-tabs-header-wrapper{
                justify-content: unset;
            }
        }
        
        
    }
    .amz-app-tab{
       
    }
    .amz-app-tabItems{
            
        margin-top: 6px;
        .amz-app-tabItem{
            width: calc(100% - 8px); 
            height: 160px;
            padding: 8px;
        }
    }
   }
   :global{
        // .amz-app-model{
        //     width: 95%;
        // }
    }
}