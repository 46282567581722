.amz-chat{
    height: 100vh;
    .amz-chat-layoutcontent{
        display: flex;
        flex-direction: column;
        background-color: #EDEFF6;

        .amz-chat-content{
            overflow-y: auto;
            flex: 1;
            .amz-chat-chatitem{
                display: flex;
                // padding:15px;
                .amz-chat-chattext{
                    &:hover{
                        .remake,.copytext{
                            display: flex;
                        }
                    }
                    .amz-chat-chattextcon{
                        
                        border-radius: 8px;
                        background-color: #fff;
                        min-width: 64px;

                    }
                    .amz-chat-chattextdo{
                        display: flex;
                        justify-content: space-between;
                        font-size: 12px;
                        margin-top: 8px;
                        padding: 0 20px;
                        height: 18px;
                    }
                    .remake{
                        color:#3C6EF6;
                        cursor: pointer;
                        display: none;
                    }
                    .copytext{
                        color:#333;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        display: none;
                        
                    }
                    // text-align: justify;
                }
                .amz-chat-chatuser{
                    width: 36px;
                    height: 36px;
                    border-radius: 8px;
                    background-color: rgba(60, 110, 246, 0.2);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    color: #3C6EF6;
                    font-weight: bold;



                }
                &.amz-chat-chatitem-ai{
                    .amz-chat-chattext{
                        margin-left: 20px;

                    }
                }
                &.amz-chat-chatitem-self{
                    flex-direction: row-reverse;
                    .amz-chat-chatuser{
                    font-size: 12px;

                    }
                    .amz-chat-chattext{
                        margin-right: 20px;

                    }
                }
    
            }
    
        }
    }
    
    
    .amz-chat-textarea{
        padding:15px;
        margin: 0 auto;
        max-width: 900px;
        width: 90%;
        border-radius: 10px;
        position: relative;
        background-color: #fff;
        margin-bottom: 15px;
        .amz-chat-sendbtn{
            position: absolute;
            padding: 15px;
            bottom: 0px;
            right: 10px;
        }
    }
    .amz-chat-quck{
        max-width: 900px;
        width:90%;
        margin: 0 auto;
        color: #333;
        .amz-chat-quckList{
            display: flex;
            overflow-y: auto;
            flex-wrap: wrap;
            .amz-chat-quckItem{
                border-radius: 8px;
                background-color: #F1F6FE;
                // margin-right: 16px;
                cursor:pointer;
                &:hover{
                    box-shadow: 0 4px 10px rgba(0,0,0,.1);
                }
            }
        }
    }
    .amz-chat-quckques{
        padding: 0 87px;
        max-height: 200px;
        overflow-y: auto;
        .amz-chat-quckquesItem{
            +.amz-chat-quckquesItem{
                margin-top: 8px;
            }
            border-radius: 5px;
            border: 1px solid transparent;
            padding: 5px 10px;
            &.active{
                border-color: #3C6EF6;
            }
        }
    }
}
.amz-chat-list{
    background:linear-gradient(173.67deg, rgba(60, 110, 246, 0.0616) 0%, rgba(123, 61, 255, 0.0168) 49.54%, rgba(255, 255, 255, 0.28) 104.58%);
    height: 100%;
    padding:24px 15px ;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .amz-chat-listbox{
        flex: 1;
        margin-top: 15px;
        overflow-y: auto;
        .amz-chat-listitem{
            border: 2px solid transparent;
            border-radius: 8px;
            padding: 8px 12px;
            cursor: pointer;
            position: relative;
            user-select:none;
            
            display: flex;
            .editicon,.hovericon{
                display: none;
            }
            &:hover,&.isMoble{
                background-color: #3C6EF61A;
                .hovericon{
                    +.hovericon{
                        margin-left: 4px;
                    }
                    display: inline-block;
                }
                .amz-chat-listitemtitle{
                    position: relative;
                    text-overflow: unset;
                    // &::after{
                    //     content: '';
                    //     display:block;
                    //     position: absolute;
                    //     top:0;
                    //     bottom: 0;
                    //     right: 0;
                    //     width: 30px;
                    //     background:linear-gradient(270deg, #E2E4FE 13.33%, rgba(226, 227, 254, 0.37) 100%);;
                    // }
                }
            }
            &.isMoble{
                background-color: unset;
            }
            &.amz-chat-listactive{
                background-color: #3C6EF61A;

            }
            .amz-chat-listitemtitle{
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: bold;
                line-height: 24px;
            }
           
        }
    }
    .amz-chat-listbottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}