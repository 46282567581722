
    
    .amz-app-tabs{
        display: flex;
        flex-direction: column;
        background-color: #f0f2f6;
        height: calc(100vh - 65px);
        overflow: auto;

    }
    .amz-app-tab{
        margin: 0 auto;
        padding:0 10px;
        width: 100%;
        box-sizing: border-box;
    }
    .self-icon{
        filter: grayscale(1);
        width:14px;
        height:14px;
        color:'inherit';
        &.active{
            filter: unset;
        }
    }
 
    .amz-app-tabItems{
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        .amz-app-tabItem{
            box-sizing: border-box;
            padding:24px 16px;
            width: calc(100% - 20px); 
            background-color: #fff;
            height: 184px;
            border-radius: 5px;
            border: 1px solid rgba(255,255,255,.1);
            box-shadow: 0 2px 20px #c8cacf99;
            position: relative;
            margin: 0 auto;
            cursor: pointer;
            .tabitem-title{
                color:#545454;
                font-size: 16px;
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .tabitem-content{
                color: #545454;
                font-size: 12px;
                margin-top: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            .tabitem-from{
                font-size: 12px;
                position: absolute;
                color: #bebebe;
                right: 16px;
                bottom: 12px;
                display: flex;
                align-items: center;
            }
            .tabitem-tool{
                position:absolute;
                left: 6px;
                bottom: 0;
                padding: 10px;
            }
        }
    }
    


@media only screen and (max-width: 600px) {
   
    .amz-app-tab{
        width: 100vw;

        :global{
            .arco-tabs-header-wrapper{
                justify-content: unset;
            }
        }
        
        
    }
    
    .amz-app-tabItems{
            
        margin-top: 6px;
        gap: 6px;
        grid-template-columns: repeat(2,1fr);
        .amz-app-tabItem{
            width: 100%;
            height: 160px;
            padding: 8px;
        }
    }
   
}