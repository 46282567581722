.amz-prompt{
    height: calc(100vh - 65px);
    .iconTrigger{
        position: absolute;
        top:80px;
        right:-12px;
        background-color:#fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        z-index: 1;
    }
    .amz-prompt-content{
        // display: flex;
        height: 100%;
        &.isMoble{
            .amz-prompt-form{
                height: unset;
                width: 100%;
            }
            .amz-prompt-print{
                height: unset;
                min-height: 300px;
            }
        }
        .amz-prompt-form{
            width: 40%;
            max-width: 560px;
            height:calc(100vh - 65px);
            position: relative;
            .amz-prompt-formCon{
                height: 100%;
                overflow-y: auto;
                padding:0 15px;
                padding-bottom: 80px;
                box-sizing: border-box;
            }
            .amz-prompt-formTitle{
                font-size: 24px;
                padding:10px 0;
            }
            .amz-prompt-formDescript{
                font-size: 14px;
                color: #a7a7a7;
            }
            .amz-prompt-send{
                position: absolute;
                bottom: 15px;
                width: 80%;
                left: 10%;
            }
            .amz-prompt-formReset{
                font-size: 12px;
                cursor: pointer;
                color:#aaa;
                text-align: center;
                &:hover{
                    color:#222
                }
            }
        }
        .amz-prompt-print{
            flex:1;
            height:100%;
            box-sizing: border-box;
            background-color: #f0f2f6;
            padding:0 15px 30px;
            position: relative;
            overflow-y: auto;
          
            .amz-prompt-printTitle{
                font-size: 24px;
                padding: 10px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: sticky;
                top:0;
                background-color: #f0f2f6;
                span{
                    font-size: 14px;
                    display: block;
                    color: #767676;
                    cursor: pointer;
                }
            }
            .amz-prompt-printContent{
                font-size: 16px;
                margin-top: 15px;
            }
            .amz-prompt-printEmpty{
                color:#767676;
                font-size: 14px;
                text-align: center;
                margin-top: 100px;
            }

        }
    }
    
}